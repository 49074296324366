@import 'src/assets/styles/variables.scss';

.divider {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: block;
  &.with-border{
    margin-left: 15px;
    max-width: calc(100% - 30px);
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  &.with-title{
    padding-bottom: 0;
    padding-top: 15px;
    span{
      font-weight: 600;
      font-size: 20px;
      color: $themeSecondaryColor;
    }
  }
}
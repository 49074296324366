@import 'src/assets/styles/variables.scss';

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.absolute {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
  }
  .indicator {
    display: inline-block;
    width: 60px;
    height: 60px;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    border: 0.4em solid transparent;
    border-top-color: $themePrimaryColor;
    border-radius: 50%;
    animation: 1s loader-05 linear infinite;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: -0.4em;
      left: -0.4em;
      border: 0.4em solid $themePrimaryColor;
      border-radius: 50%;
      opacity: 0.4;
    }
  }
  &.small{
    .indicator{
      width: 34px;
      height: 34px;
      border: 0.3em solid transparent;
      border-top-color: $themePrimaryColor;
      &:before{
        top: -0.3em;
        left: -0.3em;
        border: 0.3em solid $themePrimaryColor;
      }
    }
  }
}

@keyframes loader-05 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

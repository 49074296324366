@import 'src/assets/styles/variables.scss';

.login-page{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .col-login{
    max-width: 360px;
    span{
      &.error{
        color: $danger;
        font-size: 12px;
      }
    }
    input{
      &.error{
        border-color: $danger;
      }
    }
    .logo{
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .img{
        margin-bottom: 15px;
      }
      b{
        font-size: 26px;
        line-height: 1;
      }
      span{
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}
$primary: #316cbe;
$container-max-widths: (
        sm: 768px,
        md: 992px,
        lg: 1140px,
        xl: 1280px
);

@import '~bootstrap/scss/bootstrap.scss';

$themePrimaryColor: #3c8dbc;
$themePrimaryColorHover: #245979;
$themeSecondaryColor: #09142b;
$themeTertiaryColor: #fbce00;
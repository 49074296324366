//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&display=swap');
@import 'normalize.scss';
@import 'variables';

body,
html {
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;

  a,
  button {
    transition: 0.2s linear all;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #495057;
  }

  .app {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #f5f7fb;

    .card-title {
      font-size: 18px;
      color: #495057;
    }

    .form-footer {
      margin-top: 30px;
    }

    .page {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;

      &-main {
        width: 100%;
        padding-bottom: 100px;

        .modal {
          display: block !important;
          background: rgba(0, 0, 0, 0.7);

          .modal-actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }

        > .header {
          width: 100%;
          background: #fff;
          border-bottom: 1px solid rgba(0, 40, 100, 0.12);

          .logo {
            display: flex;

            .img {
              img {
                max-width: 40px;
              }
            }

            .title {
              display: flex;
              flex-direction: column;
              line-height: 1;
              justify-content: center;
              margin-left: 10px;

              b {
                font-size: 20px;
                text-transform: uppercase;
              }
            }
          }

          .welcome {
            display: flex;
            border-right: 1px solid rgba(0, 40, 100, 0.12);
            align-items: center;
            padding-right: 15px;
            margin-right: 15px;
            color: #333;
          }

          .logout {
            border: none;
            background: transparent;
            color: #999;
            display: flex;
            align-items: center;
            padding: 0;

            svg {
              margin-right: 5px;
            }
          }

          .nav {
            &-item {
              margin-right: 20px;

              a {
                font-size: 14px;
                display: flex;
                align-items: center;
                color: #747881;
                padding: 15px 0;
                border-bottom: 1px solid transparent;
                text-decoration: none;
                line-height: 1;

                svg {
                  margin-right: 5px;
                  font-size: 16px;
                }

                &:hover,
                &.active {
                  color: $primary;
                  border-bottom-color: $primary;
                }
              }
            }
          }
        }
      }

      &-footer {
        background: #8e95a2;
        margin-top: auto;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 120px;
        }
      }
    }

    .main-container {
      .container {
        > .header {
          display: flex;
          justify-content: space-between;

          h2 {
            margin-bottom: 0;
          }

          .col {
            flex-grow: 0;
            padding-right: 0;
            margin-bottom: 0;

            a,
            button {
              white-space: nowrap;
            }
          }

          .header-actions {
            display: flex;
            align-items: center;
            line-height: 1;

            span {
              margin: 0;
            }

            .btn-theme {
              margin-bottom: 0;
              margin-left: 15px;
            }
          }
        }
      }

      .main-content {
        display: flex;
        flex-direction: column;
      }

      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          button {
            background: #eee;
            border: 1px solid #ccc;
            color: #666;
            padding: 5px 10px;
            height: 35px;
            min-width: 35px;
            line-height: 1;
            font-size: 14px;
          }

          &:first-child {
            button {
              border-radius: 5px 0 0 5px;
            }
          }

          &:last-child {
            button {
              border-radius: 0 5px 5px 0;
            }
          }

          &:not(:last-child) {
            button {
              border-right: none;
            }
          }

          &.current {
            button {
              background: $themePrimaryColor;
              border: 1px solid $themePrimaryColor;
              color: #fff;
            }

            & + li {
              button {
                border-left: 1px solid $themePrimaryColor
              }
            }
          }
        }
      }

      .table-content {
        width: 100%;
        padding: 0 15px;
      }

      .table-theme {
        width: 100%;
        padding: 0 !important;

        .card-header {
          background: #fff;
        }

        table {
          width: 100%;
          max-width: 100%;
          border-collapse: collapse;

          tr {
            max-width: 100%;

            th,
            td {
              padding: 10px;
              border-bottom: 1px solid #e0e5ec;

              &:first-child {
                padding-left: 25px;
              }

              &:last-child {
                padding-right: 30px;
                margin-left: auto;
                text-align: right;
              }

              &.empty {
                padding: 50px;
                text-align: center;
                font-size: 20px;
                color: #999;
              }

              &.loading {
                padding: 50px;
                text-align: center;
              }

              .totals {
                display: flex;
                flex-direction: column;

                span {
                  font-weight: 600;
                }

                small {
                  font-size: 14px;
                }
              }

              .form-group {
                min-width: 140px !important;
              }
            }

            &:last-child {
              td {
                border-bottom: none;
              }
            }

            &:nth-child(even) {
              td {
                background: #e0e5ec;
              }
            }

            th {
              color: #747981;
              font-weight: 400;
              text-transform: uppercase;
              font-size: 14px;
              white-space: nowrap;
            }

            td {
              font-size: 14px;
              line-height: 1.3;

              &:last-child,
              &.nowrap {
                white-space: nowrap;
              }

              &.text-center {
                text-align: center;
              }

              &.text-right {
                text-align: right;
              }
            }
          }
        }
      }

      form {
        .table-theme {
          padding: 0 15px;

          .description {
            min-width: 240px;
            display: block;
          }

          .card {
            .col {
              min-width: 160px;
              padding: 0;
            }

            .form-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .card {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 40, 100, 0.12);
      width: 100%;
      margin-bottom: 30px;

      form {
        display: flex;
        flex-wrap: wrap;

        .col {
          min-width: 265px;
        }

        .form-row {
          width: 100%;
        }

        .error {
          color: #dc3545;
          font-size: 12px;
        }

        .is-invalid {
          .select__control {
            border-color: #dc3545;
          }
        }

        .actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .btn-primary {
            margin-left: auto;
          }
        }
      }
    }

    .btn-theme {
      margin-top: auto;

      button {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &.full-width {
          width: 100%;
        }

        svg {
          margin-right: 5px;
        }
      }
    }

    .button {
      &-action {
        border: none;
        background: red;
        margin-left: 5px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 3px;
      }

      &-edit {
        background: $themePrimaryColor;

        &:hover {
          background: $themePrimaryColorHover;
        }
      }

      &-remove {
        background: $danger;

        &:hover {
          background: #7e2222;
        }
      }
    }
  }
}

@media print {
  @page {
    size: landscape;
  }
  body, html {
    .app {
      .page-main > .header,
      .paginator,
      .card .card-header,
      .page-footer,
      .main-container .container > .header {
        display: none;
      }

      .card,
      .card-body {
        border: none;
        padding: 0;
        box-shadow: unset;

        .select__indicators{
          display: none;
        }

        .input-group-prepend,
        .input-group-text,
        .select__control,
        input, select {
          padding: 0;
          border: none;
          box-shadow: unset;
        }

        .main-container .table-theme table tr td .form-group{
          min-width: unset !important;
        }

        .input-group-text{
          padding-right: 10px;
        }
      }

      .container {
        padding: 0px !important;
      }
    }

    .print {
      border-collapse: collapse;

      tr th,
      tr td {
        padding: 5px !important;
        border: 1px solid #000 !important;
      }

      tr td:last-child,
      tr th:last-child {
        display: none;
      }
    }

    .actions,
    .search-parts {
      display: none !important;
    }

    .table-content {
      padding: 0 !important;
    }
  }
}
